








  import { Vue, Component, Prop } from 'nuxt-property-decorator'

  @Component({
    components: {
      OneCmsGridLink: () => import('~/components/molecules/links/OneCmsGridLinkComponent.vue'),
    },
  })
  export default class OneCmsGridImageComponent extends Vue {
    @Prop({
      type: String,
      required: true,
    })
    readonly img!: string

    @Prop({
      type: String,
      required: false,
    })
    readonly link?: string

    @Prop({
      type: String,
      required: false,
    })
    readonly alt?: string
  }
